import React, { useState, useEffect } from 'react'
import debug from 'debug'
import { Grid, Card } from 'semantic-ui-react'
import { RestoreScrollPosition } from 'AppSrc/ui'
import { initialItem, selectors as refreshSelectors } from 'AppSrc/refresh/reducer'
import { getItemUri } from 'AppSrc/components/registrants/helpers'
import { itemsSort, itemsIfEvent } from 'AppSrc/refresh/helpers'
import PageLoader from 'AppSrc/components/pageloader'
import ItemCard from './ItemCard'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'

import './style.css'
import { updateTopMenuContent } from 'AppSrc/components/topmenu/reducer'

debug.enable('items/index:*')
// const log = debug('items/index:log')
// const info = debug('items/index:info')
// const error = debug('items/index:error')

const Items = () => {
  const dispatch = useAppDispatch()

  const items = useAppSelector(state => refreshSelectors.items(state))
  const settings = useAppSelector(state => refreshSelectors.settings(state))

  const [loadingExpired, setLoadingExpired] = useState<boolean>(false)

  const itemTopMenuName = 'Items'
  const pageLoadingDelay = 2000

  useEffect(() => {
    dispatch(updateTopMenuContent(<div className="top-menu-title">{itemTopMenuName}</div>))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // do not stay in loading state more than 2 seconds
    const timeout = setTimeout(() => setLoadingExpired(true), pageLoadingDelay)

    return () => timeout && clearTimeout(timeout)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const itemsNotLoaded =
    Object.keys(items).length === 1 && Object.values(items)[0].slug === 'item-slug'

  if (!loadingExpired && itemsNotLoaded) {
    return (
      <Grid
        textAlign="center"
        verticalAlign="middle"
        style={{ height: '100vh', alignItems: 'center' }}
      >
        <PageLoader />
      </Grid>
    )
  }

  const localItems = Object.keys(items).length === 0 ? initialItem : items
  // log(Object.values(localItems).filter(itemsIfEvent(settings)))

  return (
    <div>
      <RestoreScrollPosition />
      <Grid
        container
        style={{ marginTop: '7rem', marginBottom: '2rem' }}
        className="grid-container"
      >
        <Grid.Column width={16}>
          <Card.Group>
            {Object.values(localItems)
              .filter(itemsIfEvent(settings))
              .sort(itemsSort)
              .map((item: DbItemType) => (
                <ItemCard
                  key={getItemUri(item)}
                  item={item}
                  shouldShowDescription
                  shouldShowItemButtons
                />
              ))}
          </Card.Group>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default Items
